import { useEffect } from "react";

import { useState } from "react";

import React from "react";
import { FaArrowUp } from "react-icons/fa";

export default function ScrollToTop() {
  useEffect(() => {
    if (typeof window == undefined) {
      return;
    }
    window.addEventListener("scroll", scroll);
  });

  const [scrolled, setScrolled] = useState(false);

  const scroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 250) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <div>
      {scrolled && (
        <button
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="w-12 h-12 rounded-full fixed bottom-5 right-6 bg-[#f04d23] flex justify-center items-center z-[99]"
          aria-label="bottom to top "
        >
          <FaArrowUp className="text-white text-xl font-light" />
        </button>
      )}
    </div>
  );
}
