import React, { useEffect, useState } from "react";
import Button from "../Common/Button";

import contact from "../../assets/contact.svg";
import { useForm } from "react-hook-form";
import axios from "axios";

import ReactGA from "react-ga";
import Modal from "../Common/Modal";

import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const [fileUploaded, setFileUploaded] = useState(false);

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const service = [
    {
      id: 1,
      name: "UI / UX",
    },
    {
      id: 2,
      name: "Web Development",
    },
    {
      id: 3,
      name: "Mobile App Development",
    },
    {
      id: 4,
      name: "Testing",
    },
    {
      id: 5,
      name: "Digital Marketing",
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    if (fileUploaded) {
      const timeout = setTimeout(() => {
        setFileUploaded(false);
      }, 50000);
      return () => clearTimeout(timeout);
    }
  }, [fileUploaded]);

  const onSubmit = async (data) => {
    ReactGA.event({
      category: data,
      action: "Submit",
      label: "Submit Button Clicked",
      value: data,
    });

    window.dataLayer.push({
      event: "formSubmission",
    });

    setFileUploaded(true);

    try {
      const response = await axios.post(
        "https://cresolinfoserv-v2.onrender.com/dgm-form",
        data
      );

      if (response.status === 200) {
        navigate("/thankyou");
        setFileUploaded(false);
        reset();
        // setIsModalOpen(true);
      } else {
        console.log("Failed to send application");
      }
    } catch (error) {
      console.error(error);
    }

    window.gtag("event", "conversion", {
      send_to: "11318331937/AvCZCPmP_94YEKGUgJUq",
      value: 1.0,
      currency: "INR",
    });
  };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: "virtualPageView" });
    }
  }, []);

  return (
    <div
      id="contact"
      className="3xl:pt-[71px] 2xl:pt-[60px] pt-[10px] 2xl:px-[160px] sm:px-[50px] px-[10px] pb-20 xl:space-y-10 space-y-3"
    >
      <div className="xl:flex justify-between space-x-16">
        <div>
          <h2>CONTACT US</h2>
          <form
            className="xl:space-y-6 space-y-3 xl:pt-8 self-end"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <small>
                Name <span className="text-[#EB1414]">*</span>
              </small>
              <input
                id="fullName"
                name="fullName"
                type="text"
                {...register("fullName", {
                  required: "Name is required",
                  pattern: {
                    value: /^[a-zA-Z ]+$/,
                    message: "Please enter valid name",
                  },
                })}
                onKeyUp={() => {
                  trigger("fullName");
                }}
                className={`bg-[#001324] border p-2 w-full ${
                  errors.fullName ? "border-[#EB1414]" : ""
                }`}
              />
              {errors.fullName && (
                <small className="text-[#EB1414]">
                  {errors.fullName.message}
                </small>
              )}
            </div>

            <div>
              <small>
                Email <span className="text-[#EB1414]">*</span>
              </small>
              <input
                type="text"
                aria-describedby="Email_error"
                id="Email"
                name="Email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter valid email address",
                  },
                })}
                onKeyUp={() => {
                  trigger("email");
                }}
                className={`bg-[#001324] border p-2 w-full ${
                  errors.email ? "border-[#EB1414]" : ""
                }`}
              />
              {errors.email && (
                <small className="text-[#EB1414]">{errors.email.message}</small>
              )}
            </div>

            <div>
              <small>
                Phone Number <span className="text-[#EB1414]">*</span>
              </small>
              <input
                type="text"
                aria-describedby="Phone_Number_error"
                id="phoneNumber"
                name="phoneNumber"
                {...register("phoneNumber", {
                  required: "Phone Number is required",

                  pattern: {
                    value: /^\d*(?:\.\d{1,2})?$/,
                    message: "Please enter valid Phone Number",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter 10 Digit Phone Number",
                  },
                  minLength: {
                    value: 10,

                    message: "Please enter  10 Digit Phone Number",
                  },
                })}
                onKeyUp={() => {
                  trigger("phoneNumber");
                }}
                className={`bg-[#001324] border p-2 w-full ${
                  errors.phoneNumber ? "border-[#EB1414]" : ""
                }`}
              />
              {errors.phoneNumber && (
                <small className="text-[#EB1414]">
                  {errors.phoneNumber.message}
                </small>
              )}
            </div>

            <div>
              <small>
                Which service you're looking for?
                <span className="text-[#EB1414]">*</span>
              </small>

              <select
                id="services"
                name="services"
                className={`bg-[#001324] border p-2 w-full ${
                  errors.services ? "border-[#EB1414]" : ""
                }`}
                {...register("services", {
                  required: "service is required",
                })}
                onKeyUp={() => {
                  trigger("services");
                }}
              >
                <option value="">Select service</option>
                {service.map((c, idx) => (
                  <option value={c.name} key={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
              {errors.services && (
                <small className="text-[#EB1414]">
                  {errors.services.message}
                </small>
              )}
            </div>

            <div className="w-full sm:col-span-2">
              <small>Description</small>
              <textarea
                rows="4"
                cols="60"
                type="text"
                id="description"
                name="description"
                {...register("description")}
                className="bg-[#001324] border p-2 w-full"
              />
            </div>

            <Button
              type="submit"
              name={fileUploaded ? "Please wait..." : "Submit"}
            />
          </form>

          {/* <Modal isOpen={isModalOpen} onClose={closeModal} /> */}
        </div>
        <div>
          <img src={contact} alt="" className="xl:block hidden" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
