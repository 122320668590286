import React from "react";
import { Fade } from "react-reveal";
import { useInView } from "react-intersection-observer";
import Button from "../Common/Button";
import "./Banner.css";
import { Link } from "react-scroll";

const Banner = () => {
  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);

  return (
    <div
      id="banner"
      ref={ref}
      className="2xl:px-[160px] sm:px-[50px] px-[10px] 3xl:pt-[80px] 2xl:pt-[76px] pt-[10px] space-y-10 "
    >
      <div className="space-y-2">
        <Fade bottom when={visible}>
          <h1
            className={`tracking-wider sm:w-[50%] md:leading-[70px] animate__fadeIn ${
              visible ? "animate-fadeIn" : ""
            }`}
          >
            TRANSFORMING IDEAS INTO DIGITAL SUCCESS
          </h1>
        </Fade>
        <Fade bottom delay={300} when={visible}>
          <p
            className={`text-[17px] tracking-wider animate__fadeIn ${
              visible ? "animate-fadeIn" : ""
            }`}
          >
            MASTERING DESIGN, CODE, AND REACH FOR A REMARKABLE WEB PRESENCE
          </p>
        </Fade>
      </div>
      <Fade bottom delay={600} when={visible}>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="hover:cursor-pointer"
        >
          <Button name="CONTACT US" />
        </Link>
      </Fade>
    </div>
  );
};

export default Banner;
