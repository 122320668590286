import React from "react";

import logo from "../../assets/logo.svg";
import { MdOutlineLocalPhone, MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <div className="2xl:px-[160px] sm:px-[50px] px-[10px]">
      <div className="md:flex justify-between bg-[#001324] md:px-10 md:p-8 p-2 rounded-xl space-y-6 md:space-y-0 md:divide-y-0 divide-y-[1px]  ">
        <div className="md:space-y-6 space-y-3">
          {/* <div>
            <p className="text-[20px] text-[#E6E6E6]">OPENING HOURS</p>
            <p>24/7</p>
          </div> */}
          <div>
            <p className="text-[20px] text-[#E6E6E6]">PHONE NUMBERS</p>
            <div className="flex items-center space-x-2">
              <MdOutlineLocalPhone />
              <a href="tel:+919581222335">+91 9581 222 335</a>
            </div>
            <div className="flex items-center space-x-2">
              <MdOutlineLocalPhone />
              <a href="tel:+919581222335">+91 9985 444 558</a>
            </div>
          </div>

          <div>
            <p className="text-[20px] text-[#E6E6E6]">EMAIL ID</p>
            <div className="flex items-center space-x-2">
              <MdEmail />
              <a href="mailto:bdm@cresolinfoserv.com">bdm@cresolinfoserv.com</a>
            </div>
          </div>
        </div>
        <div className="md:space-y-6 space-y-3 ">
          <p className="text-[20px] text-[#E6E6E6] mt-2 md:mt-0">SERVICES</p>
          <div className="grid grid-flow-row">
            <a
              href="https://cresolinfoserv.com/ui-ux-design-service-india"
              target="_blank"
            >
              UI/UX Design
            </a>
            <a
              href="https://cresolinfoserv.com/web-developement-service-india"
              target="_blank"
            >
              Web Development
            </a>
            <a
              href="https://cresolinfoserv.com/mobile-development-service-india"
              target="_blank"
            >
              Mobile App Development
            </a>
            <a
              href="https://cresolinfoserv.com/functional-testing-service-india"
              target="_blank"
            >
              Testing
            </a>
            <a
              href="https://cresolinfoserv.com/socialmedia-marketing-services"
              target="_blank"
            >
              Digital Marketing
            </a>
          </div>
        </div>
        <div className="grid md:justify-items-end md:space-y-6 space-y-5">
          <img src={logo} alt="logo" className="mt-5 md:mt-0" />
          <a
            href="https://www.google.com/search?q=3rd+Floor%2C+Flat+no%3A301%2C+Cresol+Infoserv+Pvt.+Ltd%2C+MIG-37%2C+Kukatpally+Housing+Board+Colony%2C+Hyderabad%2C+Telangana+500072&rlz=1C1CHBF_enIN1047IN1047&oq=3rd+Floor%2C+Flat+no%3A301%2C+Cresol+Infoserv+Pvt.+Ltd%2C+MIG-37%2C+Kukatpally+Housing+Board+Colony%2C+Hyderabad%2C+Telangana+500072&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzc4N2owajGoAgCwAgA&sourceid=chrome&ie=UTF-8"
            target="_blank"
            className="md:text-right"
          >
            Cresol Infoserv Pvt. Ltd,
            <br />
            MIG-37, Vasavi Srinivasam, Flat Number. 301,
            <br /> 3rd Floor, KPHB Colony, Road Number. 1, <br />
            Kukatpally, Hyderabad-500072.
          </a>
        </div>
      </div>
      <div className="p-8 text-center">
        <p>
          Powered by Cresol | ©Copyright 2023 Cresol Infoserv | All Rights
          Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
