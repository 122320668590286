import React, { useRef, useEffect, useState } from "react";

const LazyImage = ({ src, alt }) => {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: isVisible ? "transparent" : "#315a81",
        display: "inline-block",
      }}
    >
      <img
        ref={imgRef}
        src={isVisible ? src : ""}
        alt={alt}
        style={{
          opacity: isVisible ? 1 : 0,
          transition: "opacity 0.5s",
          display: "block",
        }}
      />
    </div>
  );
};

export default LazyImage;
