import React from "react";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";

import downArrow from "../../assets/downArrow.svg";
import ourCapabilities from "../../assets/ourCapabilities.svg";
import { MdOutlineArrowOutward } from "react-icons/md";
import { Link } from "react-router-dom";

const Services = () => {
  const data = [
    {
      id: 1,
      label: "UI/UX Design",
      link: "https://www.cresolinfoserv.com/ui-ux-design-service-india",
      content:
        "CRESOL provide extensive website design services with all the latest trending technologies. We provide a variety of resources that can help your business whether you are planning to design a website for your start-up or looking to build a company website from basic, then we are the best and suitable choice for you.",
      content2:
        "We explore innovative digital experiences by offering a blend of technology, creativity, and personalization in UI and UX development services. We design captivating user experiences with a significant impact on customer satisfaction rate, brand value, and conversions.",
      content3:
        "Our talent of knowledge and experience in UX research and design with the best-fit tools, technologies, and services has paved their success graphs in the manifold. Our team of skilled creative designers understands designing concepts and delivers impressive UI and UX designs for strategic business growth.",
    },
    {
      id: 2,
      label: "Web Development",
      link: "https://www.cresolinfoserv.com/web-developement-service-india",
      content:
        "A unique base in website designing and development has made us one of the leading web development companies in India. At Cresol, we keep the pace with strong knowledge, development and experience to propose web solutions that are best suited to your business.",
      content2:
        "A website plays an important role in representing your ideas and long term goals. A company profile alone is worthless, so we help you create an informative and outstanding website that will catch the attention of your customers. As a leading web development company, we have skilled and experienced designers and developers who create flexible and on-demand websites for businesses.",
      content3:
        "CRESOL offers custom website development services including WordPress development, e-commerce website development, Magento development, custom web app development, Joomla development, content management system, mobile app development, mobile responsive website, Internet Marketing & Digital Marketing. Our web specialists are committed to providing the best products and services to ensure a great business ahead",
    },
    {
      id: 3,
      label: "Mobile app Development",
      link: "https://www.cresolinfoserv.com/mobile-development-service-india",
      content:
        "Cresol will offer end-to-end cycle of mobile app development services including planning, design, integration, and management. Be it a customer-oriented or enterprise-level application, we provide full mobile application development solutions across distinctive verticals.",
      content2:
        "Converting your Goal into reality. Put up a tailor-made mobile app to deliver experiences aggressively.",
      content3:
        "Technologically drive your business reach across the right and targeted audience with innovative Android, iOS, cross-platform, mobile apps, and PWAs. Rely on our years of mobile application development services expertise and exclusive access to future-ready technologies.",
    },
    {
      id: 4,
      label: "Product Development",
      link: "https://www.cresolinfoserv.com/product-development-service-india",
      content:
        "Cresol Helped start-ups, SMEs, ISVs, and enterprises build the best software products from scratch, faster time to market, and scale ahead of their other potential competitors by having 10 plus years of realtime experience in software product development services We proudly say we are among the finest software product development firms because we have a skilled and talented product development team that can deliver through technical and functional suitability to confirm software product development.",
      content2:
        "Our software development model prefers that the customer is involved at all stages of development and transparency is maintained.",
      content3:
        "By keeping the customer involved we ensure a good enough product in all stages of software development. It allows the customer to understand the core at development and a part of the Cresol team. Here we make you feel not just a part of the action but also leading from the front. The Cresol development model places the customer at the helm of the process thus raising the confidence and returns from the product delivered.",
    },
    {
      id: 5,
      label: "Testing",
      link: "https://www.cresolinfoserv.com/functional-testing-service-india",
      content:
        "At Cresol, where we're reshaping the landscape of software testing for the modern digital era. Our software solutions are crafted to revolutionize the testing process, ensuring that your applications are not just functional, but exceptional. We understand that in today's fast-paced tech world, rigorous testing is the foundation of reliability, and our tools are designed to elevate your software's performance to new heights.",
      content2:
        "Precision and efficiency lie at the core of our testing software. We offer an array of testing solutions that streamline your development cycle, allowing you to identify bugs, glitches, and performance bottlenecks with unmatched accuracy. Our tools offer comprehensive testing across various platforms, ensuring a consistent user experience regardless of device or operating system. From load testing that simulates real-world usage to security testing that safeguards your data, our software is your shield of quality.Our software development model prefers that the customer is involved at all stages of development and transparency is maintained.",
      content3:
        "Innovation is the heartbeat of the tech world, and our software testing solutions are here to ensure your innovations thrive. Our tools don't just identify issues; they pave the way for improvement. By providing actionable insights into your software's behavior, we empower your team to fine-tune, optimize, and innovate. We believe that every test is a stepping stone towards progress, and with [Your Software Company Name]'s testing software, you're not just launching applications – you're launching possibilities. Step into the future of software testing with Cresol. Our commitment to excellence, precision, and innovation ensures that your software not only meets industry standards but sets new benchmarks. Experience testing like never before – experience the power of Cresol.",
    },
    {
      id: 6,
      label: "Digital Marketing",
      link: "https://www.cresolinfoserv.com/socialmedia-marketing-services",
      content:
        " At CRESOL, we're dedicated to revolutionizing the way businesses connect with their audience in the digital age. Our software solutions are designed to empower your digital marketing strategies, ensuring your brand stands out in a crowded online landscape. Whether you're aiming to boost your search engine visibility, harness the power of data for informed decisions, or create compelling content that resonates, our tools are your pathway to success.",
      content2:
        "With our advanced SEO capabilities, we don't just optimize websites; we optimize opportunities. Our software integrates seamlessly to enhance your website's visibility on search engines. By implementing effective SEO techniques, we help you climb the search result rankings, driving organic traffic and valuable leads. Your website becomes a magnet for those actively seeking your services, translating into higher conversion rates and sustainable growth.",
      content3:
        "In the digital realm, engagement is the key currency. Our software empowers you to engage your target audience effortlessly. From social media amplification that extends your brand's reach to seamless email campaigns that nurture leads, our tools ensure you're always in sync with your audience. By harnessing the power of data-driven insights, you'll have a competitive edge, tailoring your strategies for maximum impact. Step into a new era of digital marketing with CRESOL and unlock unparalleled growth possibilities.",
    },
  ];

  const AccordionItem = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={({ state: { isEnter } }) => (
        <>
          <h2 className="font-medium xl:text-xl text-xl">{header}</h2>
          <img
            className={`ml-auto xl:w-[24px] w-[15px] mt-3 transition-transform duration-700 ease-in-out  ${
              isEnter && "rotate-180"
            }`}
            src={downArrow}
            alt="downArrow"
          />
        </>
      )}
      className="border-t border-slate-400"
      buttonProps={{
        className: ({ isEnter }) =>
          `flex w-full xl:p-5 p-3 text-left  ${isEnter && ""}`,
      }}
      contentProps={{
        className: "transition-height duration-700  ease-in-out ",
      }}
      panelProps={{ className: "xl:px-8 p-3 w-full" }}
    />
  );

  return (
    <div
      id="services"
      className="3xl:pt-[71px] 2xl:pt-[60px] sm:pt-[35px] pt-[10px] 2xl:px-[160px] sm:px-[50px] px-[10px]  xl:flex justify-between xl:space-x-14 space-y-10 xl:space-y-0 pb-20"
    >
      <div className="w-[50%] space-y-5">
        <h2>OUR CAPABILITIES</h2>
        <img src={ourCapabilities} alt="" />
      </div>
      <div className="w-full">
        <Accordion className="space-y-4" transition transitionTimeout={700}>
          {data.map((d, idx) => (
            <AccordionItem header={d.label}>
              <div className="space-y-4 pb-4">
                <p>{d.content}</p>
                <p>{d.content2}</p>
                <p>{d.content3}</p>
              </div>
              <Link to={d.link} target="_blank">
                <small className="text-xs border rounded-full p-2 px-6 flex items-center w-fit ">
                  {d.label}
                  <MdOutlineArrowOutward className="ml-2" />
                </small>
              </Link>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Services;
