import React from "react";
import design from "../../assets/BannerServices/UIuxdesign.svg";
import website from "../../assets/BannerServices/website.svg";
import mobile from "../../assets/BannerServices/mobileapp.svg";
import testing from "../../assets/BannerServices/Testing.svg";
import dgm from "../../assets/BannerServices/Digitalmarketing.svg";
import LazyImage from "../Common/LazyImage";

const BannerServices = () => {
  const data = [
    {
      id: 1,
      image: design,
      name: "UI/UX DESIGN",
      link: "https://cresolinfoserv.com/ui-ux-design-service-india",
    },
    {
      id: 2,
      image: website,
      name: "WEBSITE",
      link: "https://cresolinfoserv.com/web-developement-service-india",
    },
    {
      id: 3,
      image: mobile,
      name: "MOBILE APP",
      link: "https://cresolinfoserv.com/mobile-development-service-india",
    },
    {
      id: 4,
      image: testing,
      name: "TESTING",
      link: "https://cresolinfoserv.com/functional-testing-service-india",
    },
    {
      id: 5,
      image: dgm,
      name: "DIGITAL MARKETING",
      link: "https://cresolinfoserv.com/socialmedia-marketing-services",
    },
  ];
  return (
    <div className="md:flex justify-between 2xl:px-[160px] sm:px-[50px] px-[10px]   2xl:mt-24 sm:mt-16 mt-10 md:space-x-10 space-y-5 mb-10">
      <div className="grid w-[25%]">
        <h2 className="sm:justify-self-end">SERVICES</h2>
      </div>

      <div className="flex text-center space-x-8">
        {data.map((s, idx) => (
          <div className="space-y-4 ">
            <a href={s.link} target="_blank">
              <LazyImage key={idx} src={s.image} alt="" />

              <h2 className="xl:text-[17px] sm:text-[10px] text-[8px] ">
                {s.name}
              </h2>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BannerServices;
