import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Fade } from "react-reveal";

const Clients = () => {
  const data = [
    {
      description:
        "I am pleased with the services Cresol Infoserv has provided me to develop the design and functionality of our online shopping website.Cresol Infoserv has helped our web presence grow immensely. I would really like to work with them again.",
      team: "RR Team",
      companyName: "RR Jewellers E Commerce",
      logo: "1",
    },
    {
      description:
        "We at Amnet are pleased to find Cresol Infoserv as an ERP solution that offers excellent value, as well as the specific functionality that our business requires. Cresol and our team did a fantastic job of working together to complete this project",
      team: "Amnet Team",
      companyName: "Amnet ERP",
      logo: "2",
    },
    {
      description:
        "We have approached Cresol Infoserv to create a CMS for our website, and it has completely transformed our content management process. The user-friendly interface, powerful features, and intuitive workflow have made it incredibly easy for our team to update and publish content.Cresol has saved us time and effort, allowing us to focus on delivering excellent service to our audience.",
      team: "HTS Team",
      companyName: "HTS",
      logo: "3",
    },
  ];
  var settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="3xl:pt-[71px] 2xl:pt-[60px] pt-[10px] 2xl:px-[160px] sm:px-[50px] px-[10px] pb-20 space-y-6">
      <Fade bottom>
        <h2 className="text-center">OUR HAPPY CLIENTS</h2>
      </Fade>
      <div className="pt-4">
        <Slider {...settings}>
          {data.map((d, i) => (
            <div className="md:px-48 px-6" key={i}>
              <div className="flex flex-col md:gap-6 gap-4">
                <Fade bottom delay={600} className="">
                  <div className="text-center">
                    <p className="md:text-2xl text-lg font-medium">
                      {d.companyName}
                    </p>
                  </div>
                  <div className="flex flex-col text-center">
                    <p>{d.description}</p>
                    <small> - {d.team}</small>
                  </div>
                </Fade>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Clients;
