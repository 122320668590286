import React from "react";

import { IoCheckmarkCircleOutline } from "react-icons/io5";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PT3XPN5W",
};

TagManager.initialize(tagManagerArgs);

const Thankyou = () => {
  return (
    <div className="grid place-items-center place-content-center h-[60vh] text-center space-y-6">
      <IoCheckmarkCircleOutline className="sm:text-4xl text-xl text-green-500" />
      <div>
        <h1>Thank you!</h1>
        <p>
          We will contact you very soon. We are delighted to have served you.
          Your trust in us means everything.
        </p>
      </div>
    </div>
  );
};

export default Thankyou;
