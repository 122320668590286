import React, { useState } from "react";

import logo from "../../assets/logo.svg";
import Button from "./Button";

import { BiMenuAltRight } from "react-icons/bi";

import { RxCross2 } from "react-icons/rx";
// import { Link } from "react-router-dom";

import { Link } from "react-scroll";

const Header = () => {
  const [opened, setOpened] = useState(true);

  const handleNav = () => {
    setOpened(!opened);
  };

  return (
    <div className="2xl:px-[160px] sm:px-[50px] px-[10px] flex md:justify-around justify-between items-center p-6 xl:space-x-36 space-x-10 w-full sticky top-0 bg-[#001324] z-50">
      <a href="/">
        <img className="md:w-[140px] w-[90px]" src={logo} alt="" />
      </a>

      <div className="md:flex items-center justify-between w-full hidden">
        <div className="flex xl:space-x-20 space-x-4">
          <a href="/" className="hover:cursor-pointer">
            <h3>HOME</h3>
          </a>

          <Link
            to="https://cresolinfoserv.com/about"
            target="_blank"
            className="hover:cursor-pointer"
          >
            <h3>ABOUT US</h3>
          </Link>

          <Link
            to="https://cresolinfoserv.com/web-developement-service-india"
            target="_blank"
            className="hover:cursor-pointer"
          >
            <h3>SERVICES</h3>
          </Link>
        </div>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="hover:cursor-pointer"
        >
          <Button name="CONTACT US" />
        </Link>
      </div>

      <div onClick={handleNav} className="md:hidden block">
        {!opened ? (
          <RxCross2 size={40} className="icon" />
        ) : (
          <BiMenuAltRight size={40} className="icon" />
        )}
      </div>

      {/* mobile */}

      <div
        className={
          !opened
            ? "text-[#000000] font-semibold fixed right-2 top-[12%] overflow-auto w-[50%] h-fit bg-white text-start pl-4 p-3 z-40"
            : "fixed hidden"
        }
      >
        <Link to="/" className="hover:cursor-pointer">
          <h3 className="mb-3">HOME</h3>
        </Link>
        <Link
          to="https://cresolinfoserv.com/about"
          target="_blank"
          className="hover:cursor-pointer"
        >
          <h3 className="mb-3">ABOUT US</h3>
        </Link>

        <Link
          to="https://cresolinfoserv.com/web-developement-service-india"
          target="_blank"
          className="hover:cursor-pointer"
        >
          <h3 className="mb-3">SERVICES</h3>
        </Link>
        <Link to="/contact" className="hover:cursor-pointer">
          <h3 className="mb-3">CONTACT US</h3>
        </Link>
      </div>
    </div>
  );
};

export default Header;
