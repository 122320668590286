import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Common/Header";
import ScrollToTop from "./components/Common/ScrollToTop";
import ReactGA from "react-ga";
// import ContactUs from "./components/Contact/ContactUs";
import Footer from "./components/Common/Footer";
import Thankyou from "./pages/Thankyou";

const Tracking_Id = "AW-11318331937";

ReactGA.initialize(Tracking_Id);

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thankyou" element={<Thankyou />} />
        {/* <Route path="/contact" element={<ContactUs />} /> */}
      </Routes>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default App;
