import React, { useEffect } from "react";
import Banner from "../components/HomePage/Banner";
import BannerServices from "../components/HomePage/BannerServices";
import banner from "../assets/banner.svg";
import About from "../components/HomePage/About";
import Services from "../components/HomePage/Services";
import Counter from "../components/HomePage/Counter";
import CaseStudies from "../components/HomePage/CaseStudies";
import ClientsLogos from "../components/HomePage/ClientsLogos";
import Clients from "../components/HomePage/Clients";
// import ContactUs from "../components/HomePage/ContactUs";
import Footer from "../components/Common/Footer";
import ReactGA from "react-ga";
import GetInTouch from "../components/HomePage/GetInTouch";
import ContactUs from "../components/Contact/ContactUs";

const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <div
        className="sm:bg-cover bg-contain bg-no-repeat bg-right pt-8"
        style={{
          backgroundImage: `url(${banner})`,
        }}
      >
        <Banner />
        <BannerServices />
      </div>
      <div className="bg-gradient-to-t from-[#00172c] to-[#011324]">
        <About />
      </div>
      <div className="bg-gradient-to-t from-[#00172c] to-[#011324]">
        <Services />
      </div>
      <div className="bg-gradient-to-t from-[#00172c] to-[#011324]">
        <Counter />

        <CaseStudies />

        <ClientsLogos />
      </div>
      <div className="bg-gradient-to-t from-[#00172c] to-[#011324]">
        <Clients />
      </div>
      <div className="bg-gradient-to-t from-[#00172c] to-[#011324]">
        {/* <GetInTouch /> */}
        <ContactUs />
      </div>
    </div>
  );
};

export default Home;
