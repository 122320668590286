import React from "react";
import { Fade } from "react-reveal";
import { useInView } from "react-intersection-observer";
import Button from "../Common/Button";
import about from "../../assets/about.svg";
import "./About.css";

const About = () => {
  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);

  return (
    <div
      id="about"
      ref={ref}
      className=" 2xl:pt-[60px] pt-[10px] space-y-4 lg:space-y-0 lg:flex justify-between 2xl:px-[160px] sm:px-[50px] px-[10px] pb-20"
    >
      <div className="md:w-[50%] md:space-y-4 space-y-2 tracking-widest self-end">
        <Fade bottom when={visible}>
          <h2>ABOUT US</h2>
        </Fade>
        <div className="md:space-y-4 space-y-2">
          <Fade bottom delay={300} when={visible}>
            <p>
              Cresol is a one of the most significant customer oriented company
              offering Mobile app, Software Development, Web Development , and
              Business Development Services in current trending technologies.
              Our skilled team of experienced developers and designers work
              efficiently with clients to understand their Startup ideas and
              create custom solutions that exceed their anticipations.
            </p>
          </Fade>
          <Fade bottom delay={500} when={visible}>
            <p>
              We are committed to delivering top-notch software solutions that
              meet the distinctive needs of each and every beloved client. We
              work efficiently with their clients to understand their business
              requirements and develop unique solutions that are customized to
              their specific needs.
            </p>
          </Fade>
        </div>
        <Fade bottom delay={700} when={visible}>
          <a href="https://cresolinfoserv.com/about" target="_blank">
            <Button name="ABOUT US" />
          </a>
        </Fade>
      </div>

      <img className="2xl:w-[550px] sm:w-[450px]" src={about} alt="about" />
    </div>
  );
};

export default About;
