import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from "../../assets/clientLogos/codeMantra.svg";
import image2 from "../../assets/clientLogos/arinos.svg";
import image3 from "../../assets/clientLogos/strive.svg";
import image4 from "../../assets/clientLogos/amnet.svg";
import image5 from "../../assets/clientLogos/mps.svg";
import image6 from "../../assets/clientLogos/jouve.svg";
import image7 from "../../assets/clientLogos/ace.svg";
import image8 from "../../assets/clientLogos/hts.svg";
import image9 from "../../assets/clientLogos/ssGroups.svg";
import image10 from "../../assets/clientLogos/pure.svg";
import image11 from "../../assets/clientLogos/acade.svg";
import image12 from "../../assets/clientLogos/nebula.svg";
import image13 from "../../assets/clientLogos/apex.svg";

const ClientsLogos = () => {
  const data = [
    {
      id: 1,
      image: image1,
    },
    {
      id: 2,
      image: image2,
    },
    {
      id: 3,
      image: image3,
    },
    {
      id: 4,
      image: image4,
    },
    {
      id: 5,
      image: image5,
    },
    {
      id: 6,
      image: image6,
    },
    {
      id: 7,
      image: image7,
    },
    {
      id: 8,
      image: image8,
    },
    {
      id: 9,
      image: image9,
    },
    {
      id: 10,
      image: image10,
    },
    {
      id: 11,
      image: image11,
    },
    {
      id: 12,
      image: image12,
    },
    {
      id: 13,
      image: image13,
    },
  ];
  var settings = {
    autoplay: true,
    infinite: data.length < 3 ? false : true,
    speed: 600,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="2xl:px-[160px] sm:px-[50px] px-[10px]  pb-20  ">
      <Slider {...settings}>
        {data.map((d, i) => (
          <div className="flex justify-center md:px-[30%] px-[10px] bg-white">
            <img className="w-[100px] h-[100px]  " src={d.image} alt="" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientsLogos;
